.comment {
	padding: .5rem 1rem;
	border-radius: 5px;
}
.comment:last-child {
	margin-bottom: 1.3rem;
}
.comment > .comment-title {
	padding: .3rem .5rem;
	background-color: #8da7db;
	color: #163064;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	display: flex;
	justify-content: space-between;
}
.comment > .comment-title > .comment-title-date {
	display: inline-block;
	color: #586e99;
}
.comment > .comment-text {
	padding: .3rem .5rem;
	border: 3px solid #8da7db;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.comment-form {
	padding: 1rem 1.5rem;
}