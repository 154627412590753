
.error {
	color: #dc3545;
}

.error-label {
	margin-top: 5px;
}

.file-item:hover {
	background-color: #F1B0B7;
}