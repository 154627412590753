.jumbo {
	padding: 0;
	min-height: 15rem;
}

.main-background {
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(../../assets/main.jpg);
}

.sub-background {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 60%;
	background-image: url(../../assets/it.jpg);
}

.landing-background {
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(../../assets/home.jpg);

	margin-bottom: 3em;
	background-position: 50% 60%;
	height: calc(50vh - 250px);
}

.bg-cover {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align:center;
	background-color: rgba(141, 167, 219, .5);
	min-height: 20vh;
	height: 100%;
}

@media only screen and (max-width: 768px) {

	.landing-background {
		max-height: 250px;
		background-position: 50% 60%;
	}

}
