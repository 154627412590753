.badge-success {
	background-color: #92c258;
}

.badge-primary {
	background-color: #8da7db;
}

.badge-danger {
	background-color: #c44a41;
}

.card h4 {
	background-color: #8da7db;
	width: 120%;
	margin-left: -10%;
	padding: .5rem 1rem;
	padding-left: 10%;
	color: #163064;
}

.pointer {
	cursor: pointer;
}
