html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
}

a {
  color: #8da7db !important;
}

.btn-primary {
  background-color: #8da7db !important;
  border: none !important;
  margin-bottom: 1rem;
}

.btn-primary:hover {
  background-color: #95abd6 !important;
}

.center {
  text-align: center;
}

.welcome-header {
  color: white;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.error-message {
  padding-top: 5px;
  color: red;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
}
