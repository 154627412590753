.solklart-card {
  margin: 10px 0;
  min-height: 13em;
  cursor: pointer;
  opacity: 0.95;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.solklart-card:hover {
  opacity: 0.97;
  box-shadow: 0 0.5rem 1.3rem rgba(0, 0, 0, 0.3) !important;
  transform: scale(1.02);
}

.solklart-card-icon {
  bottom: 15px;
  font-size: 18px;
  position: absolute;
}

.solklart-card-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
}

.solklart-card-text {
  height: 120px;
  font-size: 16px;
  overflow: hidden;
}

.solklart-info-col {
  position: absolute;
  top: 0rem;
  right: 0.5rem;
}

.solklart-info-btn {
  padding: 0rem 0.6rem;
  background-color: rgb(141, 167, 219) !important;
  border-radius: 100%;
  color: rgb(255, 255, 255) !important;
  font-size: 1rem !important;
}

.solklart-info-btn:hover {
  background-color: rgb(74, 95, 136) !important;
  border-color: none !important;
}
.solklart-info-btn:focus {
  box-shadow: none !important;
}
