.footer {
  background-color: #141618;
  padding: 1rem;
  margin-top: 40px;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
}

.footer-wrapper {
  padding-top: 10px;
  display: flex;
  font-size: 0.9em;
  width: 90%;
  max-width: 900px;
  margin: auto;
}

.footer-corp {
  flex-grow: 1;
}

@media only screen and (max-width: 600px) {
  .footer {
    height: 100px;
  }

  .footer-wrapper {
    display: block;
    text-align: center;
  }
}
